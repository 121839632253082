/* HTML5 ✰ Boilerplate */

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
}

body {
  font: 13px/1.231 sans-serif;
  *font-size: small;
}
select,
input,
textarea,
button {
  font: 99% sans-serif;
}
pre,
code,
kbd,
samp {
  font-family: monospace, sans-serif;
}

html {
  overflow-y: scroll;
}
a:hover,
a:active {
  outline: none;
}
ul,
ol {
  margin-left: 2em;
}
ol {
  list-style-type: decimal;
}
nav ul,
nav li {
  margin: 0;
  list-style: none;
  list-style-image: none;
}
small {
  font-size: 85%;
}
strong,
th {
  font-weight: bold;
}
td {
  vertical-align: top;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 15px;
}
textarea {
  overflow: auto;
}
.ie6 legend,
.ie7 legend {
  margin-left: -7px;
}
input[type='radio'] {
  vertical-align: text-bottom;
}
input[type='checkbox'] {
  vertical-align: bottom;
}
.ie7 input[type='checkbox'] {
  vertical-align: baseline;
}
.ie6 input {
  vertical-align: text-bottom;
}
label,
input[type='button'],
input[type='submit'],
input[type='image'],
button {
  cursor: pointer;
}
button,
input,
select,
textarea {
  margin: 0;
}
input:valid,
textarea:valid {
}
input:invalid,
textarea:invalid {
  border-radius: 1px;
  -moz-box-shadow: 0px 0px 5px red;
  -webkit-box-shadow: 0px 0px 5px red;
  box-shadow: 0px 0px 5px red;
}
.no-boxshadow input:invalid,
.no-boxshadow textarea:invalid {
  background-color: #f0dddd;
}

::-moz-selection {
  background: #ff5e99;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #ff5e99;
  color: #fff;
  text-shadow: none;
}
a:link {
  -webkit-tap-highlight-color: #ff5e99;
}
button {
  width: auto;
  overflow: visible;
}
.ie7 img {
  -ms-interpolation-mode: bicubic;
}

body,
select,
input,
textarea {
  color: #444;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}
a,
a:active,
a:visited {
  color: #607890;
}
a:hover {
  color: #036;
}

/**
 * Primary styles
 *
 * Author: David Eglin
 */

html body {
  background: #e1b88c url(../img/wood-tile.jpg) repeat left top;
  margin: 0 12px;
}

#container {
  width: 984px;
  margin: 0 auto;
  overflow: hidden;
}

header {
  width: 300px;
  height: 155px;
  padding: 25px 30px;
  color: #fff;
  background: transparent url(../img/head-bg.png) no-repeat left top;
}

h1 {
  font-size: 3em;
  font-weight: normal;
  width: 250px;
  font-family: Arvo, Helvetica, Arial, sans-serif;
}

header p.subhead {
  text-transform: uppercase;
  margin-top: 20px;
}

nav {
  margin-top: 50px;
  height: 58px;
  padding: 32px 24px 0;
  background: transparent url(../img/nav-bg.png) no-repeat left top;
  line-height: 26px;
}

nav h2 {
  font-size: 1.25em;
  font-weight: bold;
  float: left;
  margin-right: 30px;
  text-transform: uppercase;
  color: #fff;
}

nav ul {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

nav li {
  margin: 0 30px 0 0;
  float: left;
  display: block;
  height: 37px;
}

.ie6 nav li {
  display: inline;
}

nav li a,
nav li a:visited {
  display: block;
  height: 28px;
  padding-top: 1px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
}

.ie6 nav li a {
  float: left;
}

nav li a:hover,
nav li a:active {
  color: #fff;
  border-bottom: 8px solid #fff;
}

#main {
  padding-bottom: 40px;
  background: transparent url(../img/paper-shadow.png) no-repeat left bottom;
}

#content {
  margin: 0 12px;
  width: 924px;
  padding: 12px 0 0 36px;
  min-height: 500px;
  background: #fefefe url(../img/paper-bottom.jpg) no-repeat left bottom;
}

.ie6 #content {
  width: 888px;
}

h2 {
  font-size: 3.5em;
  font-weight: normal;
  color: #c00;
  clear: both;
  font-family: 'Nothing You Could Do', cursive;
  margin-bottom: 0.5rem;
}

h2.with-thumbs {
  margin-bottom: 0;
}

#content p.thumbs-info {
  margin-bottom: 2em;
  color: #999;
}

#content .col ul {
  margin-bottom: 1em;
}

div.col {
  float: left;
  width: 414px;
  margin-right: 48px;
}

.ie6 div.col {
  margin-right: 24px;
}

body div.gallery {
  margin-right: 38px;
  width: 424px;
}

.ie6 body div.gallery {
  margin-right: 14px;
}

#content p {
  margin-bottom: 1em;
}

#content p a {
  color: #f00;
  font-size: 1.2em;
  font-weight: bold;
}

blockquote {
  font-size: 1.2em;
  display: block;
  width: 99.9%;
  float: left;
  clear: both;
  margin-top: 1.5em;
}

#content p.references-intro {
  margin-bottom: 0;
}

cite {
  font-size: 0.8em;
  color: #999;
  font-style: italic;
  display: block;
  float: left;
  width: 338px;
  padding-top: 2px;
}

a.more {
  color: #f00;
  display: block;
  float: left;
  text-decoration: none;
}

img.vetassess-logo {
  display: block;
  margin-top: 70px;
}

a.more:hover {
  text-decoration: underline;
}

div.contact-wrapper {
  background-color: #666;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  margin-right: 38px;
  color: #fff;
  overflow: hidden;
}

.ie6 div.contact-wrapper {
  background-color: #666;
  border-bottom: 20px solid #666;
}

div.contact-wrapper a {
  color: #fff;
  font-size: 1.2em;
}

div.contact-wrapper ul {
  margin: 20px 0 0;
  padding: 0;
  overflow: hidden;
}

div.contact-wrapper li {
  float: left;
  width: 230px;
  list-style: none;
  padding: 0 0 0 40px;
  background: transparent no-repeat left top;
  height: 33px;
  line-height: 23px;
}

div.contact-wrapper li.email {
  background-image: url(../img/icon-email.png);
}

div.contact-wrapper li.phone {
  background-image: url(../img/icon-phone.png);
}

p.preamble {
  font-size: 1.25em;
  font-weight: bold;
}

.gallery a.thumbnail {
  display: block;
  float: left;
  margin: 0 30px 30px 0;
}

a.thumbnail img {
  display: block;
}

#content span.hr {
  display: block;
  clear: both;
  width: 960px;
  height: 100px;
  background: transparent url(../img/hr.png) no-repeat left center;
  color: transparent;
  margin: 0 0 20px -36px;
}

footer p.copyright {
  margin: 0 0 30px 48px;
  color: #666;
}

.ir {
  display: block;
  text-indent: -999em;
  overflow: hidden;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
}
.hidden {
  display: none;
  visibility: hidden;
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.invisible {
  visibility: hidden;
}
.clearfix:before,
.clearfix:after {
  content: '\0020';
  display: block;
  height: 0;
  overflow: hidden;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
}

@media all and (orientation: portrait) {
}

@media all and (orientation: landscape) {
}

@media screen and (max-device-width: 480px) {
  /* html { -webkit-text-size-adjust:none; -ms-text-size-adjust:none; } */
}

@media print {
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }
  a,
  a:visited {
    color: #444 !important;
    text-decoration: underline;
  }
  a[href]:after {
    content: ' (' attr(href) ')';
  }
  abbr[title]:after {
    content: ' (' attr(title) ')';
  }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox,
#cboxOverlay,
#cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
#cboxWrapper {
  max-width: none;
}
#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}
#cboxMiddleLeft,
#cboxBottomLeft {
  clear: left;
}
#cboxContent {
  position: relative;
}
#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
#cboxTitle {
  margin: 0;
}
#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  cursor: pointer;
}
.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}
.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}
#colorbox,
#cboxContent,
#cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

/* 
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay {
  background: url(../img/overlay.png) repeat 0 0;
  opacity: 0.9;
  filter: alpha(opacity = 90);
}
#colorbox {
  outline: 0;
}
#cboxTopLeft {
  width: 21px;
  height: 21px;
  background: url(../img/controls.png) no-repeat -101px 0;
}
#cboxTopRight {
  width: 21px;
  height: 21px;
  background: url(../img/controls.png) no-repeat -130px 0;
}
#cboxBottomLeft {
  width: 21px;
  height: 21px;
  background: url(../img/controls.png) no-repeat -101px -29px;
}
#cboxBottomRight {
  width: 21px;
  height: 21px;
  background: url(../img/controls.png) no-repeat -130px -29px;
}
#cboxMiddleLeft {
  width: 21px;
  background: url(../img/controls.png) left top repeat-y;
}
#cboxMiddleRight {
  width: 21px;
  background: url(../img/controls.png) right top repeat-y;
}
#cboxTopCenter {
  height: 21px;
  background: url(../img/border.png) 0 0 repeat-x;
}
#cboxBottomCenter {
  height: 21px;
  background: url(../img/border.png) 0 -29px repeat-x;
}
#cboxContent {
  background: #fff;
  overflow: hidden;
}
.cboxIframe {
  background: #fff;
}
#cboxError {
  padding: 50px;
  border: 1px solid #ccc;
}
#cboxLoadedContent {
  margin-bottom: 28px;
}
#cboxTitle {
  position: absolute;
  bottom: 4px;
  left: 0;
  text-align: center;
  width: 100%;
  color: #949494;
}
#cboxCurrent {
  position: absolute;
  bottom: 4px;
  left: 58px;
  color: #949494;
}
#cboxLoadingOverlay {
  background: url(../img/loading_background.png) no-repeat center center;
}
#cboxLoadingGraphic {
  background: url(../img/loading.gif) no-repeat center center;
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none;
}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
  outline: 0;
}

#cboxSlideshow {
  position: absolute;
  bottom: 4px;
  right: 30px;
  color: #0092ef;
}
#cboxPrevious {
  position: absolute;
  bottom: 0;
  left: 0;
  background: url(../img/controls.png) no-repeat -75px 0;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
}
#cboxPrevious:hover {
  background-position: -75px -25px;
}
#cboxNext {
  position: absolute;
  bottom: 0;
  left: 27px;
  background: url(../img/controls.png) no-repeat -50px 0;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
}
#cboxNext:hover {
  background-position: -50px -25px;
}
#cboxClose {
  position: absolute;
  bottom: 0;
  right: 0;
  background: url(../img/controls.png) no-repeat -25px 0;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
}
#cboxClose:hover {
  background-position: -25px -25px;
}

/*
  The following fixes a problem where IE7 and IE8 replace a PNG's alpha transparency with a black fill
  when an alpha filter (opacity change) is set on the element or ancestor element.  This style is not applied to or needed in IE9.
  See: http://jacklmoore.com/notes/ie-transparency-problems/
*/
.cboxIE #cboxTopLeft,
.cboxIE #cboxTopCenter,
.cboxIE #cboxTopRight,
.cboxIE #cboxBottomLeft,
.cboxIE #cboxBottomCenter,
.cboxIE #cboxBottomRight,
.cboxIE #cboxMiddleLeft,
.cboxIE #cboxMiddleRight {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF);
}
